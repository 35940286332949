.contact{
    width: 100%; height: 100%;
    background-image:url("https://uploads-ssl.webflow.com/631ec5866e474e5b101f6a41/6321d5ce1e631f33e1848708_form-right-bg.svg"); 
    background-repeat:no-repeat; background-position: right;
    color: white;
    display: flex; justify-content: center; align-items: center; flex-direction: column;
}
.contact div{
    width: 40%; margin-top: 2%;
}
.contact label{
    font-size: 20px; font-weight: lighter !important;
}
.contact input{
    height: 7vh; background-color: transparent; border: 1px solid white;
    border-radius: 5px; padding-left: 2%    ;
}
.send-btn{
    width: 30%; height: 7vh; border: none; background-color: #E9373E;
    font-size: 20px; border-radius: 5px;
}


@media screen and (max-width:767px) {
    .contact div{
        width: 90% !important;
    }
}