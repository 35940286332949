@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
body{
    padding: 0%; margin: 0%;
    font-family: 'Poppins', sans-serif;
}
.active {
    background-color: rgba(34,34,34,0.8);
  }
.head-list{
    width:40%; height:40px; display:flex;justify-content:space-evenly; align-items:center
}
.head-list:hover{
    /* background-color: lightgrey; */
    background-color: whitesmoke;
    border-radius: 10px;
}
.redbox{
    width:500px; height:500px; background-color:rgba(0,0,255,0.05); border-radius:50%; 
    box-shadow:50px 50px 180px rgba(0,0,255,0.05), -50px -50px 180px rgba(0,0, 255,0.05); 
    top:0; left:700px; position:absolute; z-index: -10; 
}
.bluebox{
    width:500px; height:500px; background-color:rgba(255,0,0,0.05); border-radius:50%; 
    box-shadow:50px 50px 180px rgba(255,0,0,0.05), -50px -50px 180px rgba(255,0,0,0.05); 
    top:250px; left:10px; position:absolute; z-index: -10; 
}