.carousel_sub{
 width: 100%; height: 100vh;
 position: relative;        
 background-color: grey;    
 background-size: cover;
 background-repeat: no-repeat;
 background-blend-mode: multiply;
}
.carousel_sub label{
  font-size: 50px;
}
.cardiv{
  background-image: url(../invest.jpg);
}
.cardiv1{
  background-image: url(../family.jpg);
}
.cardiv2{
  background-image: url(../advise.jpg);
}
.btn-car{
    width: 20%; height: 10%; background-color: #E9373E;
    border: none; border-radius: 5px; color: white; font-weight: 900;
}
.btn-trans{
    width: 20%; height: 10%; background-color: white; font-size: 20px;
    border: none; border-radius: 5px; color: black; font-weight: 900;
}
.btn-trans2{
    width: 20%; height: 10%; background-color: white; font-size: 20px;
    border: none; border-radius: 5px; color: black; font-weight: 900;
    color: #E9373E;
}
.btn-trans:hover{
    background-color: #E9373E; color: white; transition: 0.5s;
}
.back_image{
    width: 100%; height: 100vh;
    background-image: url("https://uploads-ssl.webflow.com/631ec5866e474e5b101f6a41/6347246cf31fa237d2636e39_digital-turn-around-bg.webp");
    background-repeat: no-repeat; position: fixed; top: 12vh; z-index: 0;
}


.home_2div{
    background-image: url("https://uploads-ssl.webflow.com/631ec5866e474e5b101f6a41/631ef0c0a6a75caf92f23378_grey-small-square-bg.png");
    background-repeat:no-repeat; background-position: right;
}
.home_subdiv{
    width: 100%;
    height: 100vh;
    background-image: url("https://uploads-ssl.webflow.com/631ec5866e474e5b101f6a41/631ef0c381f60730aa2ac5e0_top-left-grey-square-bg.png");
    background-repeat:no-repeat; background-position: left;
    display: flex;
}
.home_subdiv2{
    width: 100%;
    height: 100vh;
    display: flex;
}

.marquee{
    width:"100%";
    display: flex;
    animation: marquee 20s linear infinite;
}

.marquee2{
    width:"100%";
    display: flex;
  animation: marquee2 20s linear infinite;
  animation-delay: 10s;
}
@keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes marquee2 {
    from {
      transform: translateX(0%);
    }
    to {  
      transform: translateX(-200%);
    }
  }
  .test{
    width:100px; height:100px; border-radius:50%; overflow:hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .test-1{
    background-image: url("../rahulsingh.jpeg");
    }
  .test-2{
    background-image: url("../yogeshsir.jpeg");
  }
  .test-3{
    background-image: url("../mahendradave.jpeg");
  }
  .test-4{
    background-image: url("../sawanpawar.jpeg");  
  }


  .service-div:hover{
    scale: 1.05;
  }
  .label-res{
    display: none;
  }
  .label-WOres{
    display: block;
  }
  .home-3div-label{
    display: inline;
  }
  
  @media screen and (max-width:767px) {
    .carousel_sub{
      width: 100%;
      height: 35vh !important;
    }
    .carousel_sub label{
      font-size: 20px;
    }
    .btn-car{
      width: 40%;
      height: auto !important;
    }
    .btn-carousel{
      width: 50% !important;
      height: 5vh !important;
      font-size: 12px;
    }
    .home_2div{
      min-height:100vh ;
      margin-top: -15% !important;
    }
    .home_subdiv{
      height: auto !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .home_subdiv2{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .home_subdiv-div1{
      width: 80% !important;
      padding-left: 3%;
    }
    .home_subdiv-div1-lb{
      font-size: 30px !important;
    }
    .home_3div{
      display: flex !important;
      flex-direction: column !important; 
      min-height: 150vh !important;
      width: 80% !important;
      margin-left: 10% !important;
      margin-top: 5% !important;
    }
    .home-3divheight{
      min-height: 230vh !important;
    }
    .home_3subdiv{
      width: 100% !important;
      height: auto !important;
      padding-left: 0% !important;
      text-align: justify !important;
    }
    .blank-div{
      display: none !important;
    }
    .back_image{
      width: 100% !important;
      height: 100vh !important;
      background-size: cover !important;
      top: 0px !important; z-index: -10 !important;
    }
    .back-div-b{
      font-size: 25px !important;
    }
    .our-comp{
      font-size: 35px !important;
      margin-top: 10% !important;
    }
    .btn-trans{
      width: 40%;
     }
    .btn-trans2{
      width: 60% !important;
    }
    .home-3div-headlabel{
      font-size: 30px !important;
      width: 100% !important;
    }
    .label-res{
      display: block;
      font-size: 14px !important;
    }
    .label-WOres{
      display: none;
    }
    .home-3div-label{
      display: none !important;
    }
    .home-3div{
      width: 80% !important;
      margin-left: 10% !important;
      text-align: justify !important;
    }
    .three-div{
      display: flex !important;
      flex-direction: column !important;
    }
    .sub-three-div{
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
    }
    .car-test-div{
      width: 100% !important;
      margin-left:  0% !important;
    }
    .FAQ-div{
      width: 100% !important; margin-left: 0% !important;
    }
    .faq-que{
      width: 70% !important;
    }
    .triangle-img2{
      position: absolute !important;  width: 100% !important;
      left: 0 !important; top: 70% !important;
      rotate: 0deg !important; 
    }
    .triangle-img1{
     width: 100% !important; left: 0 !important;
    }
    .triangle-img1-red{
      width: 100% !important;
      position: absolute !important; rotate: 180deg !important;
      top: 0 !important; right: 0 !important;
    }
    .main_car-div{
      margin-top: -2% !important;
 
    }
    .red-home-div{
      height: 100vh !important;
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
    .red-home-div b{
      font-size: 25px !important;
    }
    .car-insDiv{
      height: auto !important;
      width: 80% !important; margin-left: 10% !important;
    }
    .marquee img{
      width: 200px !important;
    }
    .marquee2 img{
      width: 200px !important;
    }
    .test-b{
      font-size: 40px !important;
    }
    .test-label{
      font-size: 15px !important;
    }
    .test{
      width:100px; height:100px; border-radius:50%; overflow:hidden;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }


 @media only screen and (max-width: 767px) and (min-width: 380px)  {
      .home_3div{
        gap: 10% !important;
      }
      .red-home-div{
        height: 80vh !important;
      }
      .home-3div-headlabel{
        margin-top: 15% !important;
      }
  }




/* .image-ani{
  animation: image-side 1s;
}

.image-ani1{
  animation: image-side1 1s;
}
.img-side{
  animation: image-side1 1s;
}
.img-side1{
  animation: image-side 1s;
}
.three-Div-img{
  animation: threedivimg 1s;
}
@keyframes image-side {
  0%{
    margin-left: 40%;
    opacity: 0.1;
  }
  100%{
    margin-left: 0%;
  }
}

@keyframes image-side1 {
  0%{
    margin-left: -20%;
    opacity: 0.1;
  }
  100%{
    margin-left: 0%;
  }
}

@keyframes threedivimg {
  0%{
    margin-top: 20%;
    opacity: 0.1;
  }
  100%{
    margin-top: 0%;
  }
} */




/* 
@media screen and (max-width:767px) {
  .image-ani{
    width: 80% !important;
  }
  
  .image-ani1{
    width: 80% !important;
  }
  .img-side{
    width: 80% !important;
  }
  .img-side1{
    width: 80% !important;
   }
  .three-Div-img{
    width: 80% !important;
  }
  @keyframes image-side {
    0%{
      margin-left: 40%;
      opacity: 0.1;
    }
    100%{
      margin-left: 0%;
    }
  }
  
  @keyframes image-side1 {
    0%{
      margin-left: -20%;
      opacity: 0.1;
    }
    100%{
      margin-left: 0%;
    }
  }
  
  @keyframes threedivimg {
    0%{
      margin-top: 20%;
      opacity: 0.1;
    }
    100%{
      margin-top: 0%;
    }
  }
} */