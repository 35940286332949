.footer{
    width: 100%; height: 80vh;
    background-color: rgba(34,34,34,.95);
    color: white; display: flex; 
    justify-content: space-around; padding-top: 7%;
    /* align-items: baseline; */
    position: relative ;z-index: 2;
}

.btn-cont{
    width: 20%; height: 15%; background-color: white; border: none;
    border-radius: 5px;
}
.btn-cont:hover{
    background-color: #E9373E ; color: white; font-weight: bold;
    transition: 0.5s;
}
.anch-scale:hover{
    scale: 1.1;
}
@media screen and (max-width:767px) {
    .btn-cont{
        width: 50%;
    }
    .footer-uper{
        height: 30vh !important;
    }
    .footer-uper b{
        font-size: 20px !important;
    }
    .footer{
        height: 150vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .foot-div{
        width: 70% !important;
    }
    .foot-div img{
        width: 70% !important;
    }
    .foot-div:nth-child(2){
        margin-top: 5% !important;
    }
    .foot-div:nth-child(3){
        margin-top: 5% !important;
    }
    .foot-div:nth-child(4){
        margin-top: 5% !important;
    }
}