.header{
    width: 100%;
    height: 12.8vh;
    background-color: white;
    display: flex;
    justify-content: space-around;
    position: fixed;
    z-index: 5;
}
.head_label:hover{
    color: #E9373E;
}

::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #E9373E; 
    border-radius: 10px;
  }
.scroll_div label{
    color: grey;
    font-weight: lighter;
}



.responsive_header{
    width: 100%;
    height: 12.8vh;
    background-color: white;
    display: none;
}

@media screen and (max-width:767px) { 
    .header{
        display: none;
    }
    .responsive_header{
        width: 100% !important;
        display: flex;
        height: 12vh;
        justify-content: space-between;
        align-items: center;
        padding-right: 5%;
        padding-left: 2%;
    }
    .scroll_div{
        display: none !important;
    }
}