body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* table {
  border-collapse: separate;
} */

/* tr td:first-child:before {
  counter-increment: Serial;
  content: counter(Serial);
} */
.login {
  width: 24% !important;

  margin-top: 120px !important;
  margin-left: 38% !important;
}
.home1 {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.home2 {
  width: 40%;
}
.ast0 {
  width: 25%;
  height: 70px;

  display: flex;
  align-items: center;
}
.ast0-a {
  width: 21%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.ast1 {
  width: 40% !important;
  /* background-color: red !important; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ast2 {
  width: 90% !important;

  display: flex;
  flex-direction: row;
}
.ast2-a {
  width: 25% !important;

  display: flex;
  justify-content: space-evenly;
}
.avg1 {
  display: flex;
  width: 30%;
}
.avg2 {
  width: 10%;
}
@media screen and (max-width: 850px) {
  .avg1 {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .login {
    width: 80% !important;

    margin-left: 10% !important;
  }
  .home1 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .home2 {
    width: 90%;
    margin-left: 5%;
  }
  .ast0 {
    width: 100%;

    display: flex;
    align-items: center;
  }
  .ast1 {
    width: 100% !important;
    /* background-color: red !important; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .ast2-a {
    width: 90% !important;

    display: flex;
    justify-content: space-evenly;
  }
  .ast0-a {
    width: 70%;
    margin-top: 10px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
.drop {
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(40px);
  }
}
.drop-1 {
  height: 80px;
  width: 80px;
  top: -20px;
  left: -40px;
  z-index: -1;
}
.drop-2 {
  height: 80px;
  width: 80px;
  bottom: -30px;
  right: -10px;
}
.drop-3 {
  height: 100px;
  width: 100px;
  bottom: 120px;
  right: -50px;
  z-index: -1;
}
.drop-4 {
  height: 120px;
  width: 120px;
  top: -60px;
  right: -60px;
}
.drop-5 {
  height: 60px;
  width: 60px;
  bottom: 170px;
  left: 90px;
  z-index: -1;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

.lbody {
  background-color: rgb(89, 126, 246);
  width: 100%;
  height: 100vh;
}

.lcontainer {
  margin-left: 25%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.lcontainer .form {
  background: rgba(255, 255, 255, 0.3);
  padding: 3em;
  height: 320px;
  border-radius: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.lcontainer .form p {
  font-weight: 500;
  color: #fff;
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 60px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.lcontainer .form a {
  text-decoration: none;
  color: #ddd;
  font-size: 12px;
}
.lcontainer .form a:hover {
  text-shadow: 2px 2px 6px #00000040;
}
.lcontainer .form a:active {
  text-shadow: none;
}
.lcontainer .form input {
  background: transparent;
  width: 200px;
  padding: 1em;
  margin-bottom: 2em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.lcontainer .form input:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}
.lcontainer .form button {
  background: transparent;
  width: 200px;
  padding: 1em;
  margin-bottom: 2em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.lcontainer .form button:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}
.lcontainer .form input[type="email"]:focus,
.lcontainer .form input[type="password"]:focus {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}
.lcontainer .form button {
  margin-top: 10px;
  width: 150px;
  font-size: 1rem;
}
.lcontainer .form button:hover {
  cursor: pointer;
}
.lcontainer .form button :active {
  background: rgba(255, 255, 255, 0.2);
}
.lcontainer .form:hover {
  margin: 4px;
}





::placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  
}

.lcontainer a,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.flag {
  border-radius: 50%;
}
@media (max-width: 768px) {
  .filtersection4{
    width:94% !important;
  }
  .filtersection1{
    width:48% !important;
  }
  .pcorrect,
  .pincorrect,
  .pnormal,
  .pfnormal {
    font-size: 10px;
    margin-right: 0px;
  }
  .pactivestudents {
    width: 10%;
    font-size: 10px;
  }
  .pcorrecttotal,
  .pincorrecttotal,
  .pnormaltotal {
    display: none;
  }
}

.showAllStudent {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  counter-reset: Serial;
}

/* table {
  border-collapse: separate;
} */

.Abtn button {
  width: 40px;
  height: 40px;
  margin: 5px;
  margin-left: 9%;
  border: none;
  box-shadow: 3px 3px 8px black, inset 3px 3px 8px black,
    inset -3px -3px 8px white;
  background-color: rgb(130, 173, 238);
  border-radius: 10px;
}
.Abtn button:hover {
  background-color: greenyellow;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.stage {
  border-bottom: 3px solid #444;
  display: flex;
  height: 330px;
  width: 100%;
}
.box {
  align-self: flex-end;
  animation-duration: 2s;

  animation-iteration-count: 1;
  background-color: #f44336;
  height: 200px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
  border-radius: 50%;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: ease-in;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(-200px);
  }
  20% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-40px);
  }
  40% {
    transform: translateY(-0px);
  }
  50% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-0px);
  }
  70% {
    transform: translateY(-20px);
  }
  80% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(-10px);
    border: 1px solid;
    box-shadow: 2px 3px #03c3ec;
  }
  100% {
    transform: translateY(0);
  }
}
/* ------***------- */
/* ball shadow with bouncing animation */
/* ------***------- */



.ball {
  background: radial-gradient(farthest-corner at 60px 45px, #aaa 0%, black 50%);
  border-radius: 50%;
  height: 200px;
  width: 200px;

}

.ball-shadow {
  background: radial-gradient(50% 50%, #000 0%, #fff 100%);
  height: 50px;
  width: 200px;
}

.bouncy-castle {
	margin-top: 300px;
  height: 50%;
  left: 50%;
  position: absolute;
}

.bouncy-castle .ball-shadow {
  -webkit-animation-direction: alternate;
  -webkit-animation-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-name: grow;
  animation-iteration-count: infinite;
  bottom: 0;
  position: absolute;
  margin-left: -100px;
}

.bouncy-castle .ball {
  -webkit-animation-direction: alternate;
  -webkit-animation-duration: 1s;
  -webkit-animation-name: bounce;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-name: bounce;
  animation-iteration-count: infinite;
  margin-left: -100px;
  position: absolute;
}

@-webkit-keyframes grow {
  from {
    bottom: 0;
    margin-left: -100px;
    height: 50px;
    opacity: 1;
    width: 200px;
  }

  to {
    bottom: -15px;
    margin-left: -150px;
    height: 80px;
    opacity: 0.4;
    width: 300px;
  }
}

@keyframes grow {
  from {
    bottom: 0;
    margin-left: -100px;
    height: 50px;
    width: 200px;
  }

  to {
    bottom: 20px;
    margin-left: 0;
    height: 10px;
    width: 15px;
  }
}

@-webkit-keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 100%;
  }
}

@keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 100%;
  }
}
.subPlaces{
  width: 22.5%;
  border: 1px solid #03c3ec;
  justify-content: space-evenly;
  display: flex;
  border-radius: 15px;

  
}
.subCategory{
  display: flex !important;
}
.PVplaces{
  display: flex !important; width:"100%"
}
.selplaces{
  width: 70%
}
.selplaces1{
  width: 30%;
}
@media screen and (max-width: 800px) { 
  .imgss{
    width: 100% !important;
  
  }
  .filtersection1{
    width:48%;
  }
.side1{
  display: none !important;
}

.places{
  flex-wrap: wrap;
}
.placesFont{
  font-size: 13px;
  
}
.subPlaces{
  width: 48%;
  border-radius: 10px; margin: 2px;
}
.subCategory{
  display: flex;
  flex-direction: column !important; 
  /* flex-wrap: wrap; */
}
.sdonumber{
  font-size: 12px;
}
.mainpost{
  display: flex !important; flex-direction: column !important;
}
.postsection{
  width: 100% !important;
}
.selplaces{
  width: 340px !important;
}
.selplaces1{
  width: 340px !important;
}
.lbody{
  width: 100% !important;
}
.Llogo{
  display: none !important;
}
.lcontainer{
  left: 25% !important;
}
.imgs{
  flex-wrap: wrap;
}
}


@media screen and (min-width: 800px) { 
  .side2{
    display: none !important;
  }

  
}


.filtersection{
  width:100%;
  min-height: 10px;
  position: sticky;
  display:flex;
  flex-wrap: wrap;

}
.filtersection1{
  width:20%;
  min-height: 10px;

  display:flex;
  flex-direction: column;
  justify-content: space-around;

}
.filtersection2{
  width:95%;
  min-height: 10px;

}
.filtersection3{
  width:95%;
  min-height: 10px;

}
.filtersection4{
  width:99%;
  min-height: 10px;
  margin-top: 10px;

}
.reginput{

-webkit-backdrop-filter: blur(50px) !important;
  backdrop-filter: blur(50px) !important;
}

.reginput input{
  border: none; padding-left: 1%;
  width:48%; height:4.5vh
}
.reginput input::placeholder{
  color: grey; text-shadow: none; font-weight: 500;
}
.regis-inputs{
    width: 100%;
    min-Height: 30vh;
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width:767px) {
  .regis-inputs{
    display: flex; flex-direction: column;
  }
  .regis-inputs input{
    width: 80% !important;
  }
  .regisdiv {
    width: 80% !important; margin-left: 10%;
  }
  .regisdiv input{
    width: 100% !important;
  }
  .regis-inputs button{
    width: 25% !important; align-self: center;
  }
}
.regis-inputs input{
  width:22.5%; height:5vh; border: 1px solid lightgrey; border-Radius:5px;
  outline:none; padding-left: 1%; margin-top: 10px;
}

.regisdiv{
  width:22.5%; height:6.8vh; margin-top: 5px;
}
.regisdiv span{
  position: absolute; margin-top: -0.5px; margin-left: 12px; 
  background-color:white; padding-left: 3px; padding-right: 3px;
  /* */
  border-Radius:5px; transition: 1s;
}
.regisdiv label{
 margin-top: -40px; margin-left: 10px;  background-color:white;
 padding-left: 3px; padding-right: 3px;
}
.regisdiv h6{
  margin-top: -50px; margin-left: 10px;  background-color:white;
 }
.regisdiv input{
  width:100%; height:100%; border: 1px solid lightgrey; border-Radius:5px;
  outline:none; padding-left: 1%; margin-top: 10px;
}
/* .regisdiv input:focus + span{
  margin-top: -1px; margin-left: 20px;
} */
/* .regisdiv select:focus + span{
 margin-top: -1px; margin-left: 20px;
} */
.regisdiv select{
  width:100% !important; height:100%; border: 1px solid lightgrey; border-Radius:5px;
  outline:none; padding-left: 1%; margin-top: 10px;
}
.regis-inputs select{
  width:22.5%; height:5vh; border: 1px solid lightgrey; border-Radius:5px;
  outline:none; padding-left: 1%; margin-top: 10px;
}
.regis-inputs button{
  width:15%; height:5vh; border:none; background-color: #E7393E; border-Radius:5px;
  outline:none; padding-left: 1%; color: white; font-weight: bold; margin-top: 10px;
}

.regis-inputs input::placeholder{
  color: black; text-shadow:none;
}
.ss{
  color: #E7393E;
}
.abc{
  color: #E7393E;
  }





.arrow{
  width: 0; height: 0; margin-top: 30px; margin-left: -80px; 
  border-top: 40px solid transparent;
  border-right: 50px solid white; 
  border-bottom: 40px solid transparent; 
}



.side{
  width: 20%;
  height: 100vh;
  background-color:white;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.09); */
  background-color: #EDF5E1;
  border-right:5px solid white
}
.newside{
  height: 100vh;
  background-color:#EDF5E1;
  border-radius: 0px 0px 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  position:fixed;
  overflow: hidden;
  transition: 0.4s;
  /* z-index: 10; */
}
.navbar{
  display: none;
}
.backbtn{
  transition: 0.3s;
}
.backbtn:hover{
  scale: 1.3;
}




@media screen and (max-width:767px) {

  .relation-div{
    width: 90% !important;
  }

  .EV-red-div{
    width: 100% !important;
    margin-top: 5% !important;
  }
  
  .side{
    display: none;
  }
  .navbar{ 
    display: inline; position: absolute;
  }
  .about-div1{
    display: flex !important; flex-direction: column-reverse !important;
}
  .about-div2{
    display: flex !important; flex-direction: column !important;
}
.about-div1-sub{
  width: 100% !important;
}
.about-div1-sub2{
  width: 100% !important;
  height: 38vh !important;
}
.about-div1-sub2 img{
  width: 90% !important;
}
.red-border-div{
  height: 100% !important; width: 90% !important;
  left: -10px !important; top: 40px !important;
}
.about-div2-sub{
  width: 100% !important;
  height: 20vh !important;
}
.about-service{
  padding-left: 5% !important;
  padding-right: 5% !important;
}
.about-service-div{
  width: 100% !important;
  margin-left: 0% !important;
}
.health-div1{
  display: flex !important;
  flex-direction: column-reverse !important;
}
.health-div1-sub{
  width: 100% !important;
}
.health-div2{
  flex-direction: column !important;
}
.health-div2-sub{
  width: 100% !important;
  min-height: 10vh !important;
  margin-top: 5% !important;
}
.car-div1{
  flex-direction: column-reverse !important;
}
.car-div1-sub{
  width: 100% !important;
}
.car-div2{
  flex-direction: column !important;
}
.car-div2-sub{
  width: 100% !important;
  min-height: 20vh !important;
  margin-top: 5% !important;
}

.resdivvv{
width: 100% !important;
}
.reddivv11{
width: 100% !important;
margin-left: 0px !important;
}

.resdiv13{
 width: 100% !important;
}
.hominsimg{
 width: 90% !important;
}
.homeinnslab{
 font-size: 35px !important;
}

.add-modal{
  width: 95% !important;
}

  .newside{
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 380px)  {
  .about-div1-sub2{
    height: 32vh !important;
  }
}

.line-ani{
  width: 20%;
  animation: line 1s  linear;
}

.line-ani1{
  animation: line1 1s  linear;
}
@keyframes line {
    from{
      width: 0%;
    }
    to{
      width: 20%;
    }
}
@keyframes line1 {
    from{
      width: 0%;
    }
    to{
      width: 10%;
    }
}
.aadhar-card::-webkit-file-upload-button{
  float: right;
}